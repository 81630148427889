import React from 'react';
import { View, Text,Image, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { MdMenu } from 'react-icons/md'; // Icône Material Design depuis react-icons

import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

type RootStackParamList = {
  Paramètres: undefined;
  // Autres écrans
};

type NavigationProp = NativeStackNavigationProp<RootStackParamList, 'Paramètres'>;

const Header = () => {
  const navigation = useNavigation<NavigationProp>();

  return (
    <View style={styles.headerContainer}>
      <Image source={require('../assets/logoblanc.png')} style={styles.logo} />
      <View style={styles.iconContainer}>
        <TouchableOpacity onPress={() => navigation.navigate('Paramètres')}>
        <MdMenu size={30} style={styles.userIcon} /> {/* Utilisation de react-icons */}
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  logo: {
    width: 150, // Ajustez la largeur de votre logo
    height: 40, // Ajustez la hauteur de votre logo
    resizeMode: 'contain', // Ajuste l'image pour qu'elle s'adapte au conteneur tout en gardant ses proportions
  },
  headerContainer: {
    width: '100%',
    height: 90,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    justifyContent: 'space-between', // Assurez-vous que le contenu est réparti sur les extrémités
    alignItems: 'center',
    paddingRight: 15,
    paddingLeft: 15,
    paddingTop: 30
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'white',
  },
  titleWeb: {
    fontSize: 30, // Taille plus grande pour le web
  },
  userIcon: {
    color: 'white',
  },
  iconContainer: {
    // Conteneur pour l'icône, cela évite que l'icône se positionne absolument
  },
});

export default Header;
