import React, { useState, useEffect, useCallback } from 'react';
import { ActivityIndicator, View, Text, StyleSheet, Image, ImageBackground, TouchableOpacity, Linking, ScrollView, Platform, Alert } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Demandes } from '../models/Demandes';
import { recupererListeDemandes, supprimerDemandeService, payer } from '../Services';
import Header from '../components/Header';
import { MdAdd, MdDelete, MdFlightTakeoff, MdCheckCircle } from 'react-icons/md'; // Vos icônes originales

type RootStackParamList = {
  DemandeDetail: { demande: Demandes };
  WebviewScreen: { url: string };
  Paiement: { bonId: number; urlPaiement: string };
  FormScreen: { demandeId: number };
  Destinations: {  };
};

type DemandesScreenNavigationProp = NativeStackNavigationProp<RootStackParamList, 'DemandeDetail'>;

const DemandesScreen = () => {
  const navigation = useNavigation<DemandesScreenNavigationProp>();

  const [isLoading, setIsLoading] = useState(false);
  const [demandes, setDemandes] = useState<Demandes[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const handleSelect = (id: number) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const fetchDemandes = async () => {
    try {
      const userJson = await AsyncStorage.getItem('@user');
      if (userJson !== null) {
        const user = JSON.parse(userJson);
        const userId = user.id_utilisateur;
        const demandesData = await recupererListeDemandes(userId);
        setDemandes(demandesData);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des demandes:', error);
    }
  };

  useEffect(() => {
    fetchDemandes();
  }, []);

  useFocusEffect(
    useCallback(() => {
      fetchDemandes();
      setSelectedIds([]); // Réinitialiser les IDs sélectionnés
    }, [])
  );

  const accederFacture = (cheminDemande: any) => {
    const url = `https://visamonde.com/demandes/${cheminDemande}/pax.pdf`;

    Linking.canOpenURL(url)
      .then((supported) => {
        if (supported) {
          Linking.openURL(url);
        } else {
          console.log("Ne peut pas ouvrir l'URL: " + url);
        }
      })
      .catch((err) => console.error("Une erreur est survenue", err));
  };

  const handlePayer = async () => {
    setIsLoading(true);
    try {
      const { urlPaiement, bonId } = await payer(selectedIds);
      console.log('URL de paiement:', urlPaiement);
      console.log('BDC:', bonId);
      navigation.navigate('Paiement', { bonId, urlPaiement });
    } catch (error) {
      console.error('Erreur lors du paiement:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteDemandeConfirmation = (id: number) => {
    if (Platform.OS === 'web') {
      const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette demande ?");
      if (isConfirmed) {
        handleDeleteDemande(id);
      }
    } else {
      Alert.alert(
        "Confirmer la suppression",
        "Êtes-vous sûr de vouloir supprimer cette demande ?",
        [
          {
            text: "Annuler",
            onPress: () => console.log("Suppression annulée"),
            style: "cancel"
          },
          {
            text: "OK",
            onPress: () => handleDeleteDemande(id)
          }
        ],
        { cancelable: false }
      );
    }
  };

  const handleDeleteDemande = async (id: number) => {
    setIsLoading(true);
    try {
      await supprimerDemandeService(id);
      const updatedSelectedIds = selectedIds.filter(selectedId => selectedId !== id);
      setSelectedIds(updatedSelectedIds);
      fetchDemandes();
    } catch (error) {
      console.error('Erreur lors de la suppression de la demande:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardClick = (item: Demandes) => {
    if (item.etat_paiement !== 'payé' && item.etat_paiement !== 'facturé') {
      handleSelect(item.id!);
    }
  };

  const getGridItemWidth = () => {
    if (Platform.OS === 'web') {
      return demandes.length === 1 ? '90%' : '45%';
    } else {
      return demandes.length === 1 ? '90%' : '45%';
    }
  };

  const gridItemWidth = getGridItemWidth();

  return (
    <ImageBackground source={{ uri: 'https://beta.visamonde.com/assets/mobile/fond.jpg' }} style={styles.background}>
      <Header />

      <TouchableOpacity
        style={styles.fullWidthFooterCard}
        onPress={() => navigation.navigate('Destinations')
        }
      >
        <MdAdd size={50} color="#000" />
        <Text style={styles.footerText}>Créer une demande</Text>
      </TouchableOpacity>

      <View style={styles.instructionContainer}>
        <Text style={styles.instructionText}>
          Pour payer, cliquer sur une ou plusieurs demande.
        </Text>
      </View>

      {isLoading ? (
        <ActivityIndicator size="large" color="#0000ff" style={styles.loadingIndicator} />
      ) : (
        <ScrollView contentContainerStyle={styles.grid}>
          {demandes.map((item, index) => {
            const isSelected = selectedIds.includes(item.id!);

            return (
              <TouchableOpacity
                key={index}
                onPress={() => handleCardClick(item)}
                style={[
                  styles.cardTouchable,
                  {
                    width: gridItemWidth,
                  }
                ]}
              >
                <View
                  style={[
                    styles.card,
                    isSelected && styles.selectedCard,
                    item.alerte === 'oui'
                      ? { borderColor: 'red' }
                      : item.etat_visa === 'en cours'
                      ? { borderColor: 'green' }
                      : item.infosdemande
                      ? { borderColor: '#00FF00' }
                      : { borderColor: '#ddd' },
                    { borderWidth: 5 },
                  ]}
                >
                  <Text>Dossier Nº{item.id}</Text>
                  <Image source={{ uri: item.urlpays }} style={styles.flag} />
                  <Text style={styles.titre}>{item.titre}</Text>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <MdFlightTakeoff size={20} color="#333" />
                    <Text style={{ marginLeft: 5 }}>{item.datedepart}</Text>
                  </View>
                  <Image source={{ uri: item.urlvoyageur }} style={styles.avatar} />
                  <Text>{`${item.prenom}`}</Text>
                  <Text>{`${item.nom}`}</Text>

                  {item.etat_visa === 'en cours' && (
                    <Text style={{ fontWeight: 'bold', color: 'green' }}>
                      En cours de traitement
                    </Text>
                  )}

                  {item.assurancevisa === 'oui' ? (
                    <>
                      <Text>Assurance: 20€</Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        Prix Total: {(item.prixconsulat || 0) + (item.prixintervention || 0) + 20}€
                      </Text>
                    </>
                  ) : (
                    <Text style={{ fontWeight: 'bold' }}>
                      Prix Total: {(item.prixconsulat || 0) + (item.prixintervention || 0)}€
                    </Text>
                  )}

                  <Text>
                    {'\n'}
                    <Text style={{ fontWeight: 'bold' }}>
                      {item.infosdemande}
                    </Text>
                    {'\n'}
                  </Text>
                  <Text>{item.etat_paiement}</Text>

                  {item.etat_paiement === 'facturé' && (
                    <TouchableOpacity
                      style={styles.iosButton}
                      onPress={() => accederFacture(item.chemin)}
                    >
                      <Text style={styles.iosButtonText}>Accéder à ma facture</Text>
                    </TouchableOpacity>
                  )}

                  {item.etat_paiement !== 'payé' && item.etat_paiement !== 'facturé' && (
                    <TouchableOpacity
                      style={styles.deleteIcon}
                      onPress={() => handleDeleteDemandeConfirmation(item.id!)}
                    >
                      <MdDelete size={24} color="#FF0000" />
                    </TouchableOpacity>
                  )}

                  {/* Icône de coche pour indiquer la sélection */}
                  {isSelected && (
                    <MdCheckCircle size={24} color="#004085" style={styles.checkIcon} />
                  )}
                </View>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      )}

      {selectedIds.length > 0 && (
        <View style={styles.menuContextuel}>
          <TouchableOpacity
            style={styles.paiementButton}
            onPress={handlePayer}
          >
            <Text style={styles.paiementButtonText}>Accéder au paiement</Text>
          </TouchableOpacity>
        </View>
      )}
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  fullWidthFooterCard: {
    width: '90%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    borderRadius: 10,
    alignSelf: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  footerText: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: 'bold',
  },
  insuranceIconContainer: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    padding: 5,
  },
  iosButton: {
    backgroundColor: '#007AFF',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    marginTop: 10,
    alignSelf: 'center',
  },
  iosButtonText: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  etatVisaComplet: {
    color: 'green',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  etatVisaIncomplet: {
    color: 'red',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  paiementButton: {
    backgroundColor: 'green',
    padding: 10,
    borderRadius: 5,
  },
  paiementButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  cardTouchable: {
    width: '45%',
    margin: 5,
  },
  container: {
    flex: 1,
    padding: 10,
  },
  loadingIndicator: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  instructionContainer: {
    padding: 10,
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    width: '90%',
    borderRadius: 10,
  },
  instructionText: {
    textAlign: 'center',
    color: '#333',
    fontSize: 14,
    backgroundColor: 'white',
  },
  menuContextuel: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    padding: 10,
    borderTopWidth: 1,
    borderTopColor: '#ccc',
    alignItems: 'center',
    width: '100%',
  },
  deleteIcon: {
    position: 'absolute',
    right: 1,
    bottom: 1,
  },
  listContainer: {
    paddingHorizontal: 10,
  },
  background: {
    flex: 1,
    alignItems: 'center',
  },
  card: {
    flex: 1,
    margin: 10,
    borderWidth: 3,
    borderColor: '#ddd',
    borderRadius: 10,
    padding: 20,
    backgroundColor: '#fff',
    alignItems: 'center',
    position: 'relative',
  },
  titre: {
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
  },
  flag: {
    width: 40,
    height: 25,
    marginVertical: 10,
  },
  avatar: {
    width: 80,
    height: 80,
    borderRadius: 40,
  },
  selectedCard: {
    backgroundColor: '#cce5ff',
    borderColor: '#004085',
  },
  checkIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
});

export default DemandesScreen;
