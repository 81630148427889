import React, { useState } from 'react';
import { View, Text, TextInput, Button, StyleSheet, TouchableOpacity, Image, Modal, ActivityIndicator } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RouteProp } from '@react-navigation/native';

import modifieVoyageur from '../modifieVoyageurService';
import { Voyageurs } from '../models/Voyageurs';
import MyCameraComponent from './MyCameraComponent';
import DocumentOrPhotoPicker from './DocumentOrPhotoPicker';
import { KeyboardAvoidingView } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import RNPickerSelect from 'react-native-picker-select';


type RootStackParamList = {
  VoyageurUpdate: {
    voyageur: Voyageurs;
  };
  Voyageurs: {};
};

type VoyageurUpdateNavigationProp = NativeStackNavigationProp<RootStackParamList, 'VoyageurUpdate', 'Voyageurs'>;
type VoyageurUpdateRouteProp = RouteProp<RootStackParamList, 'VoyageurUpdate'>;

const VoyageurUpdate = () => {
  const navigation = useNavigation<VoyageurUpdateNavigationProp>();
  const route = useRoute<VoyageurUpdateRouteProp>();
  const [voyageur, setVoyageur] = useState<Voyageurs>(route.params.voyageur);
  const [nomError, setNomError] = useState(false);
  const [prenomError, setPrenomError] = useState(false);
  //const [pseudoError, setPseudoError] = useState(false);
  const [telephoneError, setTelephoneError] = useState(false);
  const [professionError, setProfessionError] = useState(false);

  // États pour la gestion de la caméra et des photos
  const [showCamera, setShowCamera] = useState(false);
  const [isPassportPhoto, setIsPassportPhoto] = useState(false);
  const [selectedPhotoUri, setSelectedPhotoUri] = useState<string | null>(null);
  const [selectedPasseportUri, setSelectedPasseportUri] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [adresse, setAdresse] = useState(voyageur.adresse || '');
  const [codePostal, setCodePostal] = useState(voyageur.cp || '');
  const [ville, setVille] = useState(voyageur.ville || '');
  const [adresseIdentique, setAdresseIdentique] = useState(voyageur.adresse ? 'non' : 'oui');
  const [etatCivil, setEtatCivil] = useState(voyageur.etat_civil || ''); // Nouvel état pour l'état civil
  const [etatCivilError, setEtatCivilError] = useState(false); // État pour l'erreur d'état civil


  const handlePhotoTaken = (uri:any) => {
    if (isPassportPhoto) {
      setSelectedPasseportUri(uri);
    } else {
      setSelectedPhotoUri(uri);
    }
    setShowCamera(false);
  };

  const handleCancel = () => {
    setShowCamera(false);
  };

  const validateForm = () => {
    let isValid = true;

     if (!voyageur.nom.trim()) {
       setNomError(true);
       isValid = false;
     } else {
       setNomError(false);
     }
     if (!voyageur.prenom.trim()) {
       setPrenomError(true);
       isValid = false;
     } else {
       setPrenomError(false);
     }

     if (!etatCivil.trim()) { // Validation pour l'état civil
      setEtatCivilError(true);
      isValid = false;
    } else {
      setEtatCivilError(false);
    }
    

   

     const isTelephoneValid = /^\d+$/.test(voyageur.telephone.trim());
     if (!isTelephoneValid) {
      setTelephoneError(true);
      isValid = false;
    } else {
      setTelephoneError(false);
    }

    if (!voyageur.profession.trim()) {
      setProfessionError(true);
      isValid = false;
    } else {
      setProfessionError(false);
    }

    const isAdresseIdentiqueValid = adresseIdentique === 'oui' || adresseIdentique === 'non';
    if (!isAdresseIdentiqueValid) {
      alert('Veuillez répondre à la question sur l\'adresse identique au passeport.');
      isValid = false;
    }

    if (adresseIdentique === 'non') {
      if (!adresse.trim()) {
        alert('Veuillez saisir l\'adresse.');
        isValid = false;
      }
      if (!codePostal.trim()) {
        alert('Veuillez saisir le code postal.');
        isValid = false;
      }
      if (!ville.trim()) {
        alert('Veuillez saisir la ville.');
        isValid = false;
      }
    }
    return isValid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setIsLoading(true); // Activer l'indicateur de chargement
      try {
        // Vérifie si de nouvelles photos ont été prises
        const newPhotoUri = selectedPhotoUri !== voyageur.url ? selectedPhotoUri : null;
        const newPasseportUri = selectedPasseportUri !== defaultPasseportUri ? selectedPasseportUri : null;

        // Mettre à jour les informations du voyageur
        await modifieVoyageur(voyageur.id, voyageur.nom, voyageur.prenom, newPhotoUri, newPasseportUri, adresse, codePostal, ville, voyageur.telephone, voyageur.profession,etatCivil );
        setIsLoading(false); // Désactiver l'indicateur de chargement
        navigation.goBack();
            } catch (error) {
        console.error('Erreur lors de la mise à jour:', error);
        setIsLoading(false); // Désactiver l'indicateur de chargement en cas d'erreur
      }
    }
  };

  // Construction de l'URL du passeport par défaut
  const defaultPasseportUri = voyageur.url.replace('photo.jpg', 'passeport.jpg');

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImageUri, setSelectedImageUri] = useState(null);

  const handleSelectPhotoUri = (uri: string) => {
    setSelectedPhotoUri(uri);
  };

  // Pour ouvrir le sélecteur de documents/photos pour le passeport
  const handleSelectPasseportUri = (uri: string) => {
    setSelectedPasseportUri(uri);
  };

  return (
    <KeyboardAvoidingView style={{ flex: 1 }} behavior="padding">
      <KeyboardAwareScrollView>
      <View style={styles.container}>
        <>
          <Text style={styles.label}>Nom:</Text>
          <TextInput
            style={[styles.input, nomError ? styles.errorInput : null]}
            onChangeText={(text) => setVoyageur({ ...voyageur, nom: text })}
            value={voyageur.nom}
          />
          {nomError && <Text style={styles.errorText}>Veuillez remplir ce champ</Text>}

          <Text style={styles.label}>Prénom:</Text>
          <TextInput
            style={[styles.input, prenomError ? styles.errorInput : null]}
            onChangeText={(text) => setVoyageur({ ...voyageur, prenom: text })}
            value={voyageur.prenom}
          />
          {prenomError && <Text style={styles.errorText}>Veuillez remplir ce champ</Text>}
        </>
         

         

          <Text style={styles.label}>Photo d'identité:</Text>
          <DocumentOrPhotoPicker
            documentType="Photo d'identité"
            onDocumentSelected={handleSelectPhotoUri}
            modeOptions={['photo', 'selectPhoto']}
          />

          <Text style={styles.label}>Passeport:</Text>
          <DocumentOrPhotoPicker
            documentType="Passeport"
            onDocumentSelected={handleSelectPasseportUri}
            modeOptions={['photo', 'selectPhoto', 'selectPdf']}
          />

          <Text style={styles.label}>Adresse identique a celle du passeport:</Text>
          <RNPickerSelect
            onValueChange={(value) => {
              setAdresseIdentique(value);
              if (value === 'oui') {
                setAdresse('');
                setCodePostal('');
                setVille('');
              }
            }}
            items={[
              { label: 'Oui', value: 'oui' },
              { label: 'Non', value: 'non' },
            ]}
            style={pickerSelectStyles}
            value={adresseIdentique}
          />

          {adresseIdentique === 'non' && (
            <>
              <Text style={styles.label}>Adresse:</Text>
              <TextInput
                style={styles.input}
                onChangeText={setAdresse}
                value={adresse}
                placeholder="Votre adresse"
              />
              <Text style={styles.label}>Code Postal:</Text>
              <TextInput
                style={styles.input}
                onChangeText={setCodePostal}
                value={codePostal}
                placeholder="Votre code postal"
              />
              <Text style={styles.label}>Ville:</Text>
              <TextInput
                style={styles.input}
                onChangeText={setVille}
                value={ville}
                placeholder="Votre ville"
              />
            </>
          )}


    <Text style={styles.label}>État Civil:</Text>
    <RNPickerSelect
        onValueChange={(value) => setEtatCivil(value)}
        items={[
          { label: 'Célibataire', value: 'célibataire' },
          { label: 'Marié(e)', value: 'marié' },
          { label: 'Divorcé(e)', value: 'divorcé' },
          { label: 'Veuf/Veuve', value: 'veuf' },
        ]}
        style={pickerSelectStyles}
        placeholder={{ label: 'Sélectionnez votre état civil', value: null }}
        value={etatCivil}
    />
    {etatCivilError && <Text style={styles.errorText}>Veuillez sélectionner un état civil.</Text>}
 

        <Text style={styles.label}>Téléphone:</Text>
          <TextInput
            style={[styles.input, telephoneError ? styles.errorInput : null]}
            onChangeText={(text) => setVoyageur({ ...voyageur, telephone: text })}
            value={voyageur.telephone}
            placeholder="Votre téléphone"
            keyboardType="phone-pad"
          />
          {telephoneError && <Text style={styles.errorText}>Veuillez entrer un numéro de téléphone valide.</Text>}

          <Text style={styles.label}>Profession:</Text>
          <TextInput
            style={[styles.input, professionError ? styles.errorInput : null]}
            onChangeText={(text) => setVoyageur({ ...voyageur, profession: text })}
            value={voyageur.profession}
            placeholder="Votre profession"
          />
          {professionError && <Text style={styles.errorText}>Veuillez remplir ce champ</Text>}

          <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.iosButton} onPress={handleSubmit}>
              <Text style={styles.iosButtonText}>Mettre à jour le voyageur</Text>
            </TouchableOpacity>
          </View>

          <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => setModalVisible(!modalVisible)}
          >
            <View style={styles.centeredModalView}>
              <Image source={{ uri: selectedImageUri || '' }} style={styles.fullSizeImage} />
              <TouchableOpacity
                style={styles.closeModalButton}
                onPress={() => setModalVisible(!modalVisible)}
              >
                <Text style={styles.closeModalButtonText}>Fermer</Text>
              </TouchableOpacity>
            </View>
          </Modal>
        </View>
      </KeyboardAwareScrollView>
    </KeyboardAvoidingView>
  );
};

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    color: 'black',
    paddingRight: 30, // pour s'assurer que l'icône déroulante est visible
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: 'purple',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30, // pour s'assurer que l'icône déroulante est visible
  },
});

const styles = StyleSheet.create({
  iosButton: {
    backgroundColor: 'black',
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    alignItems: 'center',
  },
  iosButtonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
  centeredModalView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  fullSizeImage: {
    width: '90%',
    height: '90%',
    resizeMode: 'contain',
  },
  closeModalButton: {
    backgroundColor: '#2196F3',
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    marginTop: 15,
  },
  closeModalButtonText: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  imagePickerButton: {
    backgroundColor: '#DDD',
    padding: 10,
    borderRadius: 5,
    marginTop: 10,
  },
  imagePreview: {
    width: 100,
    height: 100,
    marginTop: 10,
    borderRadius: 5,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    padding: 20,
    backgroundColor: '#fff',
  },
  label: {
    fontWeight: 'bold',
    marginTop: 20,
  },
  input: {
    width: '100%',
    borderColor: 'gray',
    borderWidth: 1,
    marginTop: 5,
    padding: 10,
    borderRadius: 5,
  },
  errorInput: {
    borderColor: 'red',
  },
  errorText: {
    color: 'red',
    alignSelf: 'flex-start',
  },
  buttonContainer: {
    marginTop: 20,
    width: '100%',
  },
});

export default VoyageurUpdate;
