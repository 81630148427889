import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, FlatList, StyleSheet, ScrollView, Image, ImageBackground, TouchableOpacity, Button, Platform, Alert } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { recupererListeVisas, recupererListePays, recupererListeVoyageurs } from '../Services';
import { Visas } from '../models/Visas';
import { Pays } from '../models/Pays';
//import { Picker } from '@react-native-picker/picker';
import RNPickerSelect from 'react-native-picker-select';
import { Linking } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons'; // Si vous utilisez react-native-vector-icons
import Header from '../components/Header';
import { TextField, MenuItem } from '@mui/material'; // Importation des composants de Material-UI
import PaysGrid from '../components/PaysGrid';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ModalSelector from 'react-native-modal-selector';
import { MdHelpOutline } from 'react-icons/md';



type RootStackParamList = {
    Formulaire_creation_voyageur: undefined;
    
  Formulaire_Inde: { title: string, prixconsulat: number, prixintervention: number, url_flag: string, validite: string, informations: string };   
  Formulaire_Template: { title: string, prixconsulat: number, prixintervention: number, url_flag: string, validite: string, informations: string };    
  Formulaire_USA: { title: string, prixconsulat: number, prixintervention: number, url_flag: string, validite: string, informations: string }; 
  Formulaire_Angola: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };    
  Formulaire_Arabie_Saoudite: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };   
  Formulaire_Australie: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };         
  Formulaire_Azerbaidjan: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };       
  Formulaire_Bahrein: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };  
  Formulaire_Benin: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };  
  Formulaire_Burkina_Faso: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };                                                                                             
  Formulaire_Cambodge: { title: string, prixconsulat: number, prixintervention: number, url_flag: string, validite: string, informations: string };                                                                                      
  Formulaire_Cameroun: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };                                                                                         
  Formulaire_Canada: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };                                                                                                                                                                                  
  Formulaire_Cap_Vert: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };  
  Formulaire_Coree_Du_Sud: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };                                                                                          
  Formulaire_Cote_d_ivoire: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };  
  Formulaire_Cuba: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };                                                                             
  Formulaire_Djibouti: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };   
  Formulaire_Dubai: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };    
  Formulaire_Egypte: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };   
  Formulaire_Ethiopie: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };   
  Formulaire_Gabon: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };                                                               
  Formulaire_Georgie: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };    
  Formulaire_Guinee: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };    
  Formulaire_Indonesie: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };                                                         
  Formulaire_Jordanie: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };     
  Formulaire_Kenya: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };   
  Formulaire_Kirghizistan: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };   
  Formulaire_Koweit: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };   
  Formulaire_Laos: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };    
  Formulaire_Lesotho: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };     
  Formulaire_Madagascar: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };     
  Formulaire_Malawi: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };      
  Formulaire_Mongolie: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };                             
  Formulaire_Mozambique: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };    
  Formulaire_Myanmar: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };                            
  Formulaire_Nouvelle_Zelande: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };     
  Formulaire_Oman: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };      
  Formulaire_Ouganda: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };    
  Formulaire_Ouzbekistan: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };    
  Formulaire_Pakistan: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };      
  Formulaire_PapouasieNG: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };    
  Formulaire_Republique_Dominicaine: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };    
  Formulaire_Seychelles: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };    
  Formulaire_Sierra_Leone: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };   
  Formulaire_Singapour: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };   
  Formulaire_Sri_Lanka: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };   
  Formulaire_Tadjikistan: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };      
  Formulaire_Tanzanie: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };    
  Formulaire_Thailande: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };      
  Formulaire_Vietnam: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };     
  Formulaire_Zambie: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };     
  Formulaire_Zimbabwe: { title: string, prixconsulat: number, prixintervention: number, url_flag: string , validite: string, informations: string };    
};


type VisasScreenNavigationProp = NativeStackNavigationProp<RootStackParamList>;

const VisasScreen = () => {
    const navigation = useNavigation<VisasScreenNavigationProp>();
    useEffect(() => {
        navigation.setOptions({
          gestureEnabled: false, // Désactive les gestes de navigation sur cet écran
        });
      }, [navigation]);
      
    const [pays, setPays] = useState<Pays[]>([]);
    const [paysVisibles, setPaysVisibles] = useState<Pays[]>([]);

    const [paysSelectionne, setPaysSelectionne] = useState<number | null>(null);
    const [visas, setVisas] = useState<Visas[]>([]);
    const [visasFiltres, setVisasFiltres] = useState<Visas[]>([]);
    const [urlInfoDestination, setUrlInfoDestination] = useState<string | null>(null); // Pour stocker l'URL d'information du pays


    useEffect(() => {
        const fetchPays = async () => {
            const paysListe = await recupererListePays();
            setPays(paysListe); // Pour le Picker : tous les pays
            const paysVisibles = paysListe.filter(pays => pays.visible === 'oui');
            setPaysVisibles(paysVisibles);
        };
        fetchPays();
    }, []);

    
// Fonction pour récupérer l'URL du pays sélectionné depuis AsyncStorage
const getUrlInfos = async () => {
    try {
      const url = await AsyncStorage.getItem('@pays_url_infos');
      if (url !== null) {
        setUrlInfoDestination(url); // Stocker l'URL dans l'état local
      }
    } catch (e) {
      console.error('Erreur lors de la récupération de l\'URL depuis AsyncStorage:', e);
    }
  };

     // Nouveau useEffect pour mettre à jour les visas basés sur le pays sélectionné dans le picker
     useEffect(() => {
        const fetchVisasFiltres = async () => {
            if (paysSelectionne !== null) {
                const visasListe = await recupererListeVisas();
                const paysSelectionneNum = Number(paysSelectionne);
                const visasFiltresTemp = visasListe.filter(v => v.id_pays === paysSelectionneNum);
                setVisasFiltres(visasFiltresTemp);
            }
        };
    
        fetchVisasFiltres();
    }, [paysSelectionne]);

    const handlePaysSelect = async (paysId: number | null) => {
        const selectedPays = pays.find(p => p.id === paysId);
        if (selectedPays) {
          setPaysSelectionne(selectedPays.id);
          try {
            await AsyncStorage.setItem('@pays_url_infos', selectedPays.url_infos+"?appli=yes");
            console.log('URL du pays sauvegardée dans AsyncStorage:', selectedPays.url_infos);
            
            // Appeler getUrlInfos pour mettre à jour l'état avec l'URL
            await getUrlInfos();
      
          } catch (e) {
            console.error('Erreur lors de la sauvegarde de l\'URL dans AsyncStorage:', e);
          }
        }
      };
      
    


      const checkVoyageursAndNavigate = async (title: string, prixconsulat: number, prixintervention: number, url_flag: string, validite: string, informations: string) => {
        const userJson = await AsyncStorage.getItem('@user');
        if (userJson !== null) {
            const user = JSON.parse(userJson);
            const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
    
            if (listeVoyageurs.length > 0) {
                // Si des voyageurs existent, naviguer vers l'écran du formulaire
                navigateBasedOnTitle(title, prixconsulat, prixintervention, url_flag, validite, informations);
            } else {
                // Aucun voyageur trouvé
                if (Platform.OS === 'web') {
                    window.alert("Aucun voyageur, veuillez créer un voyageur avant de faire une demande.");
                    // Naviguer vers l'écran de gestion des voyageurs sur le web (si applicable)
                    navigation.navigate('Formulaire_creation_voyageur'); // Remplace 'GestionVoyageurs' par le nom réel de l'écran
                } else {
                    Alert.alert(
                        "Aucun voyageur", 
                        "Veuillez créer un voyageur avant de faire une demande.", 
                        [
                            {
                                text: "OK", 
                                onPress: () => {
                                    // Naviguer vers l'écran de gestion des voyageurs sur mobile
                                    navigation.navigate('Formulaire_creation_voyageur'); // Remplace 'GestionVoyageurs' par le nom réel de l'écran
                                }
                            }
                        ]
                    );
                }
            }
        } else {
            // Si l'utilisateur n'est pas trouvé
            if (Platform.OS === 'web') {
                window.alert("Erreur, utilisateur non trouvé.");
            } else {
                Alert.alert("Erreur", "Utilisateur non trouvé.");
            }
        }
    };
    
  
  

  

  const navigateBasedOnTitle = (title: string, prixconsulat: number, prixintervention: number, url_flag: string, validite: string, informations: string) => {
    console.log(title);
    if (title.includes('Inde')) {
        navigation.navigate('Formulaire_Inde', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes('USA')) {
        navigation.navigate('Formulaire_USA', { title, prixconsulat, prixintervention, url_flag, validite,informations});
    } else if (title.includes('Angola')) {
        navigation.navigate('Formulaire_Angola', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes('Arabie Saoudite')) {
        navigation.navigate('Formulaire_Arabie_Saoudite', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes('Australie')) {
        navigation.navigate('Formulaire_Australie', { title, prixconsulat, prixintervention, url_flag, validite,informations });
    } else if (title.includes('Azerbaidjan')) {
        navigation.navigate('Formulaire_Azerbaidjan', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes('Bahreïn')) {
        navigation.navigate('Formulaire_Bahrein', { title, prixconsulat, prixintervention, url_flag , validite,informations });
    } else if (title.includes('Benin')) {
        navigation.navigate('Formulaire_Benin', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes('Burkina Faso')) {
        navigation.navigate('Formulaire_Burkina_Faso', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes('Cambodge')) {
        navigation.navigate('Formulaire_Cambodge', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes('Cameroun')) {
        navigation.navigate('Formulaire_Cameroun', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes('Canada')) {
        navigation.navigate('Formulaire_Canada', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes('Cap-Vert')) {
        navigation.navigate('Formulaire_Cap_Vert', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes('Corée Du Sud')) {
        navigation.navigate('Formulaire_Coree_Du_Sud', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Ivoire")) {
        navigation.navigate('Formulaire_Cote_d_ivoire', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Cuba")) {
        navigation.navigate('Formulaire_Cuba', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Djibouti")) {
        navigation.navigate('Formulaire_Djibouti', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Dubai")) {
        navigation.navigate('Formulaire_Dubai', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Egypte")) {
        navigation.navigate('Formulaire_Egypte', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Ethiopie")) {
        navigation.navigate('Formulaire_Ethiopie', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Gabon")) {
        navigation.navigate('Formulaire_Gabon', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Géorgie")) {
        navigation.navigate('Formulaire_Georgie', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Guinée") && !title.includes("Papouasie")) {
        navigation.navigate('Formulaire_Guinee', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Indonésie")) {
        navigation.navigate('Formulaire_Indonesie', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Jordanie")) {
        navigation.navigate('Formulaire_Jordanie', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Kenya")) {
        navigation.navigate('Formulaire_Kenya', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Kirghizistan")) {
        navigation.navigate('Formulaire_Kirghizistan', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Koweït")) {
        navigation.navigate('Formulaire_Koweit', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Laos")) {
        navigation.navigate('Formulaire_Laos', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Lesotho")) {
        navigation.navigate('Formulaire_Lesotho', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Madagascar")) {
        navigation.navigate('Formulaire_Madagascar', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Malawi")) {
        navigation.navigate('Formulaire_Malawi', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Mongolie")) {
        navigation.navigate('Formulaire_Mongolie', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Mozambique")) {
        navigation.navigate('Formulaire_Mozambique', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Myanmar")) {
        navigation.navigate('Formulaire_Myanmar', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Nouvelle-Zélande")) {
        navigation.navigate('Formulaire_Nouvelle_Zelande', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Oman")) {
        navigation.navigate('Formulaire_Oman', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Ouganda")) {
        navigation.navigate('Formulaire_Ouganda', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Ouzbékistan")) {
        navigation.navigate('Formulaire_Ouzbekistan', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Pakistan")) {
        navigation.navigate('Formulaire_Pakistan', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Papouasie")) {
        navigation.navigate('Formulaire_PapouasieNG', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("République Dominicaine")) {
        navigation.navigate('Formulaire_Republique_Dominicaine', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Seychelles")) {
        navigation.navigate('Formulaire_Seychelles', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Sierra Leone")) {
        navigation.navigate('Formulaire_Sierra_Leone', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Singapour")) {
        navigation.navigate('Formulaire_Singapour', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Sri Lanka")) {
        navigation.navigate('Formulaire_Sri_Lanka', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Tadjikistan")) {
        navigation.navigate('Formulaire_Tadjikistan', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Tanzanie")) {
        navigation.navigate('Formulaire_Tanzanie', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Thailande")) {
        navigation.navigate('Formulaire_Thailande', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Vietnam")) {
        navigation.navigate('Formulaire_Vietnam', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Zambie")) {
        navigation.navigate('Formulaire_Zambie', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else if (title.includes("Zimbabwe")) {
        navigation.navigate('Formulaire_Zimbabwe', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    } else {
        // Gérer d'autres cas ou un cas par défaut si nécessaire
        navigation.navigate('Formulaire_Template', { title, prixconsulat, prixintervention, url_flag , validite,informations});
    }
};

          

  const handleRetourPays = () => {
    setPaysSelectionne(null); // Réinitialiser la sélection du pays
};

const cardWidth = visasFiltres.length === 1 ? '90%' : '45%'; // Largeur dynamique

const data = pays.map((paysItem, index) => ({
    key: index,
    label: paysItem.libelle,
    value: paysItem.id,
  }));

const PickerComponent = () => {
  if (Platform.OS === 'web') {
      return (
          <TextField
              select
              label="Sélectionner un pays"
              value={paysSelectionne}
              onChange={(event) => handlePaysSelect(Number(event.target.value))}
              fullWidth
          >
              {pays.map((paysItem) => (
                  <MenuItem key={paysItem.id} value={paysItem.id}>
                      {paysItem.libelle}
                  </MenuItem>
              ))}
          </TextField>
      );
  } else {
    return (
        <ModalSelector
          data={data}
          initValue="Sélectionner un pays"
          onChange={(option) => handlePaysSelect(option.value)}
          style={styles.modalSelector}
          initValueTextStyle={styles.initValueTextStyle}
          selectTextStyle={styles.selectTextStyle}
          optionTextStyle={styles.optionTextStyle}
          cancelText="Retour"
        />
      );
  }
};



return (
    <ImageBackground source={{ uri: 'https://beta.visamonde.com/assets/mobile/fond.jpg' }} style={styles.background}>
        <Header  />  
       
        {paysSelectionne ? (
            <>
                            <View style={styles.menuContextuel}>
            <TouchableOpacity onPress={handleRetourPays} style={styles.retourButton}>
                <Text style={styles.retourButtonText}>Retour aux Pays</Text>
            </TouchableOpacity>

            {/* Bouton "Information Destination" */}
            <TouchableOpacity
                onPress={() => {
                if (urlInfoDestination) {
                    Linking.openURL(urlInfoDestination);
                } else {
                    Alert.alert("Information", "Aucune URL disponible.");
                }
                }}
                style={styles.infosButtonPays}
            >
                <Text style={styles.infosButtonText}>Infos Pays</Text>
            </TouchableOpacity>
            </View>

                <ScrollView contentContainerStyle={styles.grid}>
    {visasFiltres.map((item, index) => (
        <TouchableOpacity
            key={index}
            onPress={() => checkVoyageursAndNavigate(item.titre, item.prixconsulat ?? 0, item.prixintervention ?? 0, item.url, item.validite, item.informations)}
            style={[styles.cardTouchable, { width: cardWidth }]}
        >
            <View style={styles.card}>
                <Text style={styles.title}>{item.titre}</Text>
                <Text style={styles.subtitle}>{item.validite}</Text>
                <Image source={{ uri: item.url }} style={styles.avatar} />
                <Text style={styles.subtitle}>Frais de visa: {item.prixconsulat}€  </Text>
                <Text style={styles.subtitle}>Frais de services: {item.prixintervention}€</Text>
                <Text style={styles.subtitle}> {item.informations}</Text>
            </View>
        </TouchableOpacity>
    ))}
</ScrollView>


            </>
        ) : (
            <>
                 <View style={styles.pickerContainer}>
                <PickerComponent />
            </View>
            {Platform.OS === 'web' ? (
    <PaysGrid paysVisibles={paysVisibles} onSelectPays={handlePaysSelect} />
) : (
    <ScrollView contentContainerStyle={styles.grid}>
        {paysVisibles.map((item, index) => (
            <TouchableOpacity key={index} onPress={() => handlePaysSelect(item.id)} style={styles.cardTouchable}>
                <View style={styles.card}>
                    
                    <Text style={styles.title}>{item.libelle}</Text>
                    <Image source={{ uri: item.url_flag }} style={styles.flagImage} />
                    <View style={styles.photoContainer}>
                        <Image source={{ uri: item.url_image }} style={styles.photoImage} />
                    </View>
                    <TouchableOpacity onPress={() => Linking.openURL(item.url_infos)} >
                    <MdHelpOutline size={30} color="blue" />
                    </TouchableOpacity>
                </View>
            </TouchableOpacity>
        ))}
    </ScrollView>
)}

            </>
        )}
    </ImageBackground>
);


};

const pickerSelectStyles = StyleSheet.create({
    inputIOS: {
        fontSize: 16,
        paddingVertical: 12,
        paddingHorizontal: 10,
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 4,
        color: 'black',
        paddingRight: 30, // pour assurer que le texte ne soit pas coupé
    },
    inputAndroid: {
        fontSize: 16,
        paddingHorizontal: 10,
        paddingVertical: 8,
        borderWidth: 0.5,
        borderColor: 'purple',
        borderRadius: 8,
        color: 'black',
        paddingRight: 30, // pour assurer que le texte ne soit pas coupé
    },
});

const styles = StyleSheet.create({
    infosButton: {
        backgroundColor: 'green',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        width: 150
        
      },
      infosButtonPays: {
        backgroundColor: 'green',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        width: 150
        
      },
      infosButtonText: {
        color: 'white',
        fontSize: 16,
        textAlign: 'center', // Centre le texte horizontalement
        alignSelf: 'center', // Facultatif : s'assure que le texte est bien au centre du bouton
        width: '100%', // Pour s'assurer que l'espace de texte est total, ce qui aide à centrer
    },
    
    modalSelector: {
        marginVertical: 10,
        backgroundColor: 'white',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: 'white',
        padding: 10,
      },
      initValueTextStyle: {
        color: '#333',
        fontSize: 16,
      },
      selectTextStyle: {
        color: '#333',
        fontSize: 16,
      },
      optionTextStyle: {
        color: '#333',
        fontSize: 16,
      },



    retourButton: {
        backgroundColor: 'green', // Couleur de fond du bouton
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        marginRight: 10, 
    },
    retourButtonText: {
        color: 'white', // Couleur du texte
        fontSize: 16, // Taille du texte
    },
    infoIconContainer: {
        position: 'absolute',
        bottom: -3,
        
        padding: 5,
    },
    flagImage: {
        width: 40, // Largeur du drapeau
        height: 30, // Hauteur du drapeau
        resizeMode: 'contain', // Garder les proportions
        marginTop: 5, // Espacement après le texte
    },
    photoContainer: {
        width: '100%', // Prendre toute la largeur de la card
        alignItems: 'center', // Centrer la photo horizontalement
        marginTop: 10, // Espacement après le drapeau
        marginBottom: 30, // Ajouter un espace en dessous de la photo
    },
    photoImage: {
        width: '90%', // Largeur de la photo (90% de la card)
        height: 100, // Hauteur de la photo
        resizeMode: 'cover', // Couvrir l'espace disponible
        borderRadius: 10, // Arrondir les coins
    },
    pickerContainer: {
        width: '95%',
        marginHorizontal: '2.5%',
        backgroundColor: 'white',
        borderRadius: 5,
        marginBottom: 10,
        paddingTop: 0,
        borderWidth: 1, // Ajout d'une bordure pour définir clairement les limites
        borderColor: '#ccc', // Couleur de la bordure
    },
    
    pickerStyle: {
        backgroundColor: 'white', // Fond blanc pour le picker
        height: 150, // Hauteur fixe pour iOS
        width: '100%', // Largeur complète
    },
    

    menuContextuel: {
        flexDirection: 'row',       // Disposition en ligne des éléments
        justifyContent: 'space-between', // Espacement entre les boutons
        alignItems: 'center',        // Aligne les boutons verticalement au centre
        padding: 10,   
        },
    instructionContainer: {
        padding: 10,
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Un fond blanc semi-transparent
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
      },
      instructionText: {
        textAlign: 'center', // Centre le texte horizontalement
        color: '#333', // Couleur du texte
        fontSize: 14, // Taille du texte
      },
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    grid: {
        flexDirection: 'row', 
        flexWrap: 'wrap', 
        justifyContent: 'space-around',
    },
    cardTouchable: {
        width: '45%', // Réduction de la largeur pour s'adapter à deux colonnes
        marginVertical: 5,
    },
    card: {
        borderWidth: 1,
        borderColor: '#ddd',
        borderRadius: 10,
        padding: 10,
        backgroundColor: '#fff',
        alignItems: 'center',
    },
    avatar: {
        width: '100%',            // L'image occupe 100% de la largeur du conteneur
        height: 150,              // Hauteur fixe de 150px
        borderRadius: 10,         // Bords arrondis
        objectFit: 'contain',     // Maintient l'aspect ratio de l'image, elle sera entière dans le conteneur
        marginVertical: 10,       // Marge verticale
      },
      
    title: {
        fontWeight: 'bold',
        fontSize: 18,
        marginBottom: 10,
        textAlign: 'center',
    },
    subtitle: {
        color: '#666',
        marginBottom: 5,
        textAlign: 'center',
    },
    cardContent: {
        marginTop: 10,
    },
});

export default VisasScreen;
