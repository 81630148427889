import React, { useEffect, useState } from 'react';
import { View, Text, TextInput, StyleSheet, TouchableOpacity, Image, ActivityIndicator, Modal, TouchableWithoutFeedback, Keyboard } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MyCameraComponent from './MyCameraComponent';
import creationVoyageur from '../creationVoyageurService';
import DocumentOrPhotoPicker from './DocumentOrPhotoPicker';
import RNPickerSelect from 'react-native-picker-select';
import { KeyboardAvoidingView } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

type RootStackParamList = {
    Voyageurs: {};
};

type VoyageursInsertNavigationProp = NativeStackNavigationProp<RootStackParamList, 'Voyageurs'>;

const VoyageurInsert = () => {
    const navigation = useNavigation<VoyageursInsertNavigationProp>();
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [selectedPhotoUri, setSelectedPhotoUri] = useState<string | null>(null);
    const [selectedPasseportUri, setSelectedPasseportUri] = useState<string | null>(null);
    const [adresseIdentique, setAdresseIdentique] = useState('');
    const [adresse, setAdresse] = useState('');
    const [codePostal, setCodePostal] = useState('');
    const [ville, setVille] = useState('');
    const [telephone, setTelephone] = useState('');
    const [profession, setProfession] = useState('');
    const [etatCivil, setEtatCivil] = useState(''); // Ajout de l'état civil
    const [isFormValid, setIsFormValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        checkFormValidity();
    }, [nom, prenom, selectedPhotoUri, selectedPasseportUri, adresse, codePostal, ville, adresseIdentique, telephone, profession, etatCivil]);

    const checkFormValidity = () => {
        const isNomValid = nom.trim() !== '';
        const isPrenomValid = prenom.trim() !== '';
        const isPhotoUriPresent = selectedPhotoUri !== null;
        const isPasseportUriPresent = selectedPasseportUri !== null;
        const isTelephoneValid = telephone.trim() !== '';
        const isProfessionValid = profession.trim() !== '';
        const isEtatCivilValid = etatCivil.trim() !== ''; // Validation pour l'état civil
        let isAdresseValid = true;
        let isCodePostalValid = true;
        let isVilleValid = true;

        if (adresseIdentique === 'non') {
            isAdresseValid = adresse.trim() !== '';
            isCodePostalValid = codePostal.trim() !== '';
            isVilleValid = ville.trim() !== '';
        }

        setIsFormValid(
            isNomValid && isPrenomValid && isPhotoUriPresent && isPasseportUriPresent && isAdresseValid && isCodePostalValid && isVilleValid && isTelephoneValid && isProfessionValid && isEtatCivilValid
        );
    };

    const handleSubmit = async () => {
        if (isFormValid) {
            setIsLoading(true);
            try {
                const userJson = await AsyncStorage.getItem('@user');
                if (userJson) {
                    const user = JSON.parse(userJson);
                    await creationVoyageur({
                        nom,
                        prenom,
                        idUtilisateur: String(user.id_utilisateur),
                        photoUri: selectedPhotoUri,
                        passeportUri: selectedPasseportUri,
                        adresse,
                        codePostal,
                        ville,
                        telephone,
                        profession,
                        etatCivil, // Ajout de l'état civil à l'objet envoyé
                    });
                    setIsLoading(false);
                    navigation.goBack();                }
            } catch (error) {
                console.error('Erreur lors de l’envoi:', error);
                setIsLoading(false);
            }
        }
    };

    return (
        
       
                 <KeyboardAwareScrollView contentContainerStyle={styles.container}
                 style={{ width: '100%' }}>
                    {isLoading ? (
                        <ActivityIndicator size="large" color="#0000ff" />
                    ) : (
                        <>
                            <Text style={styles.label}>Nom:</Text>
                            <TextInput
                                style={styles.input}
                                onChangeText={setNom}
                                value={nom}
                            />

                            <Text style={styles.label}>Prénom:</Text>
                            <TextInput
                                style={styles.input}
                                onChangeText={setPrenom}
                                value={prenom}
                            />

                            <Text style={styles.label}>Photo d'identité:</Text>
                            <DocumentOrPhotoPicker
                                documentType="Photo d'identité"
                                onDocumentSelected={(uri: any) => setSelectedPhotoUri(uri)}
                                modeOptions={['photo', 'selectPhoto']}
                            />

                            <Text style={styles.label}>Passeport:</Text>
                            <DocumentOrPhotoPicker
                                documentType="Passeport"
                                onDocumentSelected={(uri: any) => setSelectedPasseportUri(uri)}
                                modeOptions={['photo', 'selectPhoto', 'selectPdf']}
                            />

                            <RNPickerSelect
                                onValueChange={(value: any) => setAdresseIdentique(value)}
                                items={[
                                    { label: 'Oui', value: 'oui' },
                                    { label: 'Non', value: 'non' },
                                ]}
                                placeholder={{
                                    label: 'Adresse identique à celle du passeport ?',
                                    value: null,
                                }}
                                style={pickerSelectStyles}
                            />

                            {adresseIdentique === 'non' && (
                                <>
                                    <Text style={styles.label}>Adresse:</Text>
                                    <TextInput
                                        style={styles.input}
                                        onChangeText={setAdresse}
                                        value={adresse}
                                        placeholder="Votre adresse"
                                    />
                                    <Text style={styles.label}>Code Postal:</Text>
                                    <TextInput
                                        style={styles.input}
                                        onChangeText={setCodePostal}
                                        value={codePostal}
                                        placeholder="Votre code postal"
                                    />
                                    <Text style={styles.label}>Ville:</Text>
                                    <TextInput
                                        style={styles.input}
                                        onChangeText={setVille}
                                        value={ville}
                                        placeholder="Votre ville"
                                    />
                                </>
                            )}

                            <Text style={styles.label}>État Civil:</Text>
                            <RNPickerSelect
                                onValueChange={(value: any) => setEtatCivil(value)}
                                items={[
                                    { label: 'Célibataire', value: 'célibataire' },
                                    { label: 'Marié(e)', value: 'marié' },
                                    { label: 'Divorcé(e)', value: 'divorcé' },
                                    { label: 'Veuf/Veuve', value: 'veuf' },
                                ]}
                                placeholder={{
                                    label: 'Sélectionnez votre état civil',
                                    value: null,
                                }}
                                style={pickerSelectStyles}
                            />

                            <Text style={styles.label}>Téléphone:</Text>
                            <TextInput
                                style={styles.input}
                                onChangeText={(text) => {
                                    const filteredText = text.replace(/[^0-9]/g, '');
                                    setTelephone(filteredText);
                                }}
                                value={telephone}
                                placeholder="Votre téléphone"
                                keyboardType="numeric"
                            />

                            <Text style={styles.label}>Profession:</Text>
                            <TextInput
                                style={styles.input}
                                onChangeText={setProfession}
                                value={profession}
                                placeholder="Votre profession"
                            />

                            <View style={styles.buttonContainer}>
                                <TouchableOpacity
                                    style={[styles.iosButton, !isFormValid ? styles.disabledButton : styles.enabledButton]}
                                    onPress={handleSubmit}
                                    disabled={!isFormValid}
                                >
                                    <Text style={styles.iosButtonText}>Ajouter le voyageur</Text>
                                </TouchableOpacity>
                            </View>
                        </>
                    )}
                </KeyboardAwareScrollView>
              
    );
};



const pickerSelectStyles = StyleSheet.create({
    inputIOS: {
        fontSize: 16,
        paddingVertical: 12,
        paddingHorizontal: 10,
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 4,
        color: 'black',
        paddingRight: 30, // pour s'assurer que l'icône déroulante est visible
    },
    inputAndroid: {
        fontSize: 16,
        paddingHorizontal: 10,
        paddingVertical: 8,
        borderWidth: 0.5,
        borderColor: 'purple',
        borderRadius: 8,
        color: 'black',
        paddingRight: 30, // pour s'assurer que l'icône déroulante est visible
    },
});

const styles = StyleSheet.create({
    enabledButton: {
        backgroundColor: '#000', // Bouton noir pour indiquer qu'il est actif
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderRadius: 10,
        alignItems: 'center',
      },
      disabledButton: {
        backgroundColor: '#CCCCCC', // Bouton gris clair pour indiquer qu'il est désactivé
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderRadius: 10,
        alignItems: 'center',
      },
    centeredModalView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    fullSizeImage: {
        width: '90%',
        height: '90%',
        resizeMode: 'contain',
    },
    closeModalButton: {
        backgroundColor: '#2196F3',
        borderRadius: 20,
        padding: 10,
        elevation: 2,
        marginTop: 15,
    },
    closeModalButtonText: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    iosButton: {
        backgroundColor: '#007AFF', // Couleur bleue typique des boutons iOS
        paddingVertical: 10, // Espace vertical à l'intérieur du bouton
        paddingHorizontal: 15, // Espace horizontal à l'intérieur du bouton
        borderRadius: 10, // Bords arrondis pour le bouton
        alignItems: 'center', // Centrer le texte à l'intérieur du bouton
    },
    iosButtonText: {
        color: 'white', // Texte blanc pour contraste
        fontSize: 16, // Taille de police standard
        fontWeight: 'bold', // Gras pour le texte du bouton
    },
    imagePickerButton: {
        backgroundColor: '#DDD',
        padding: 10,
        borderRadius: 5,
        marginTop: 10,
    },
    imagePreview: {
        width: 100,
        height: 100,
        marginTop: 10,
        borderRadius: 5,
    },
    container: {
        flexGrow: 1,
        alignItems: 'center',
        padding: 20,
        backgroundColor: '#fff',
    },
    label: {
        fontWeight: 'bold',
        marginTop: 20,
    },
    input: {
        width: '100%',
        borderColor: 'gray',
        borderWidth: 1,
        marginTop: 5,
        padding: 10,
        borderRadius: 5,
    },
    errorInput: {
        borderColor: 'red',
    },
    errorText: {
        color: 'red',
        alignSelf: 'flex-start',
    },
    buttonContainer: {
        marginTop: 20,
        width: '100%',
    },
});

export default VoyageurInsert;
