import React, { useEffect, useState } from 'react';
import {
  View, TextInput, StyleSheet, Text,
  ImageBackground, TouchableOpacity,Image,
  Platform
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { login } from '../Services'; // Assurez-vous que le chemin vers votre service de login est correct
import AsyncStorage from '@react-native-async-storage/async-storage';
import Icon from 'react-native-vector-icons/FontAwesome'; // Importer les icônes
import { CheckBox } from 'react-native-elements';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


type RootStackParamList = {
  Retour: undefined;
  SignUp: undefined; // Assurez-vous d'avoir une route pour l'inscription
  ForgotPassword: undefined; // Assurez-vous d'avoir une route pour le mot de passe oublié
};

type LoginScreenProp = NativeStackNavigationProp<RootStackParamList, 'Retour'>;

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false); // Pour gérer l'affichage de l'erreur de login
  const [showPassword, setShowPassword] = useState(false); // État pour afficher/masquer le mot de passe
  const navigation = useNavigation<LoginScreenProp>();
  const [rememberMe, setRememberMe] = useState(false);

useEffect(() => {
  const loadRememberedCredentials = async () => {
    const rememberedEmail = await AsyncStorage.getItem('@rememberedEmail');
    const rememberedPassword = await AsyncStorage.getItem('@rememberedPassword');
    if (rememberedEmail && rememberedPassword) {
      setEmail(rememberedEmail);
      setPassword(rememberedPassword);
      setRememberMe(true);
    }
  };
  loadRememberedCredentials();
}, []);


const handleLogin = async () => {
  setLoginError(false); // Réinitialiser l'état de l'erreur à chaque tentative
  if (!email.trim() || !password.trim()) {
    // Afficher une erreur si l'email ou le mot de passe est vide
    setLoginError(true);
    return;
  }

  try {
    const user = await login(email, password); // Remplacez par votre fonction de login
    // Sauvegarder les informations de l'utilisateur dans AsyncStorage
    await AsyncStorage.setItem('@user', JSON.stringify(user));
    await AsyncStorage.setItem('@mail', email);
    
    if (rememberMe) {
      await AsyncStorage.setItem('@rememberedEmail', email);
      await AsyncStorage.setItem('@rememberedPassword', password);
    } else {
      await AsyncStorage.removeItem('@rememberedEmail');
      await AsyncStorage.removeItem('@rememberedPassword');
    }

    setEmail('');
    setPassword('');
    navigation.navigate('Retour');
  } catch (error) {
    // Gérer l'erreur si les identifiants sont incorrects
    setLoginError(true); // Activer l'affichage de l'erreur
  }
};


  const styles = StyleSheet.create({
    checkboxContainer: {
      flexDirection: 'row',
      marginBottom: 20,
      alignItems: 'center',
    },
    checkbox: {
      marginRight: 10,
    },
    background: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    card: {
      width: '90%',
      backgroundColor: 'white',
      padding: 20,
      borderRadius: 10,
      elevation: 5,
    },
    title: {
      fontSize: 24,
      marginBottom: 20,
      textAlign: 'center',
    },
    version: {
      fontSize: 10,
      marginBottom: 2,
      textAlign: 'right',
    },
    inputContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      paddingVertical: 5,
      paddingHorizontal: 10,
      borderWidth: 1,
      borderColor: '#ccc',
      borderRadius: 5,
      marginBottom: 10,
    },
    input: {
      flex: 1,
      padding: 10,
    },
    buttonContainer: {
      backgroundColor: 'green', // Couleur de fond du bouton
      padding: 10,
      borderRadius: 5,
      width: '100%',
      marginTop: 20,
    },
    buttonText: {
      color: 'white', // Couleur du texte
      textAlign: 'center',
      fontWeight: 'bold',
    },
    errorText: {
      color: 'red',
      alignSelf: 'center',
      marginTop: 10,
    },
    linkText: {
      color: 'green',
      marginTop: 15,
      textAlign: 'center',
    },
    iconContainer: {
      padding: 10,
    },
    logo: {
      width: 215, // Largeur de l'image
      height: 59, // Hauteur de l'image
      marginBottom: 20, // Espace sous l'image
      alignSelf: 'center', // Centrer l'image
    },
    checkboxContainerWeb: {
      marginVertical: 10,
      alignItems: 'center',
    },
    checkboxLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
    },
    checkboxInput: {
      width: 20,
      height: 20,
      marginRight: 10,
      cursor: 'pointer',
    },
    checkboxText: {
      fontSize: 16,
      color: '#333',
    },
  });

  return (
    <ImageBackground source={{ uri: 'https://beta.visamonde.com/assets/mobile/fond.jpg' }} style={styles.background}>
      <View style={styles.card}>
      <Image source={require('../assets/logo.png')} style={styles.logo} />
      <View style={styles.inputContainer}>
          <TextInput
            style={styles.input}
            placeholder="Email"
            value={email}
            onChangeText={setEmail}
            autoCapitalize="none"
          />
        </View>
        <View style={styles.inputContainer}>
          <TextInput
            style={styles.input}
            placeholder="Mot de passe"
            value={password}
            onChangeText={setPassword}
            secureTextEntry={!showPassword}
          />
          <TouchableOpacity onPress={() => setShowPassword(!showPassword)} style={styles.iconContainer}>
          {showPassword ? (
  <FaEyeSlash size={20} color="#333" />
) : (
  <FaEye size={20} color="#333" />
)}          </TouchableOpacity>
        </View>
        {Platform.OS === 'web' ? (
  <View style={styles.checkboxContainerWeb}>
    <label style={styles.checkboxLabel}>
      <input
        type="checkbox"
        checked={rememberMe}
        onChange={() => setRememberMe(!rememberMe)}
        style={styles.checkboxInput}
      />
      <Text style={styles.checkboxText}>Se souvenir de moi</Text>
    </label>
  </View>
) : (
  <CheckBox
    title="Se souvenir de moi"
    checked={rememberMe}
    onPress={() => setRememberMe(!rememberMe)}
    containerStyle={{
      backgroundColor: 'transparent',
      borderWidth: 0,
    }}
    textStyle={{
      color: '#333',
      fontSize: 16,
    }}
  />
)}
        <View style={styles.buttonContainer}>
          <TouchableOpacity onPress={handleLogin}>
            <Text style={styles.buttonText}>Connexion</Text>
          </TouchableOpacity>
        </View>
        {loginError && <Text style={styles.errorText}>Utilisateur ou mot de passe erroné</Text>}
        <TouchableOpacity onPress={() => navigation.navigate('SignUp')}>
          <Text style={styles.linkText}>Créer un compte</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.navigate('ForgotPassword')}>
          <Text style={styles.linkText}>Mot de passe oublié</Text>
        </TouchableOpacity>
        <Text style={styles.version}>v1.0.12</Text>
      </View>
    </ImageBackground>
  );
};

export default LoginScreen;
